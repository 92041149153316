@import '/src/styles/Responsive.scss';

.page{

    & .page_header{
        height: 50vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        color: var(--colorTextAlt);

        @include size_M{
            justify-content: center;
        }

        & .page_header_divider{
            height: 2px;
            background: var(--colorTextAlt);
            width: 150px;
            margin: 1.25rem 0;

            @include size_M{
                margin: 1.25rem auto;
            }
        }

        & .page_header_wrapper{
            width: 400px;
            margin-left: 15%;

            & .page_header_title{
                font-size: 44px;
                font-weight: bold;
            }
    
            @include size_M{
                margin: 1rem;
                text-align: center;
            }
        }
    }
}