@import '/src/styles/Responsive.scss';
@import '/src/styles/Effects.scss';

.container_with_background{
    display: flex;
    align-items: center;
    justify-content: space-between;

    & img{
        margin: 2rem;
        width: 10%;

        @include size_S{
            width: 20%;
        }
    }

    @include size_S{
        flex-direction: column;
    }
}

.battle_image_wrapper{
    height: 400px;
    margin: 5rem 0;

    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.map_responsive{
    overflow:hidden;
    padding-bottom:35.25%;
    position:relative;
    height:0;

    @include size_S{
        padding-bottom: 60.25%;
    }
}

.map_responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.buy_ticket_paragraph{
        background: url('../../../public/images/battle_image.webp') rgba(0, 0, 0, 0.3);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
}