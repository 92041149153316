.product_card {
  margin: auto;
  box-shadow: 0 3px 6px 0 #daccc0;
  border-radius: 0;
  justify-content: space-around;

  h2 {
      text-decoration: none;
  }

  .date_title{
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    
    & > span{
        color: var(--colorPrimary);
    }
  }

  .date_subtitle{
    text-align: center;
    opacity: 0.7;
    font-size: 0.9rem;
  }
}

.product_page {
    .react-datepicker-wrapper{
        width: fit-content;

        .react-datepicker__input-container{
            input {
                padding: 0.7rem 0rem;
                text-align: center;
                font-family: inherit;
                border-radius: 5px;
                border: 1px solid;
            }
        }
    }
}
.filming_text {
    font-size: 0.9rem;
    font-style: italic;
}