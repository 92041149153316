@import '/src/styles/Responsive.scss';

.info_paragraph{
    margin: 4rem 0;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

    @include size_S{
        margin: 0 2rem;
    }

    &_title{
        font-weight: bold;
        font-size: 24px;
        text-decoration: underline;
        margin: 1rem 0;
    }

    &_content{
        white-space: pre-line;
    }

    &_additional_content{
        margin: 1rem 0;
    }
}