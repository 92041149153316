.about_us_section_title{
    font-size: 18px;
    font-weight: bold;
    line-height: 3;
}

.about_us_section_subtitle{
    font-weight: bold;
    text-decoration: underline;
}

.about_us_section_text{
    white-space: pre-line;
    line-height: 3;
}