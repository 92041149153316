.table_box{
    overflow-x: auto;
    width: 100%;
}
table {
    width: 100%;
    border-collapse: collapse;

    th, td {
        height: 3rem;
        text-align: center;
        padding: 0 0.5rem;
    }

    tbody {
        border-top: 1px solid #aeaeae;
        border-bottom: 1px solid #aeaeae;
    }

    tr td:first-of-type, th:first-of-type {
        text-align: left;
    }
}

.total_row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-weight: bold;
    height: 3rem;

    .display_currency{
        text-align: right;
        font-size: 0.9rem;
    }
}

.order_summary_header{
    display: 'flex'; 
    align-items: 'center';
    justify-content: 'space-between';
}