html, body {
    margin: 0;
    padding: 0;
    font-family: 'Merriweather', serif;

    --colorPrimary: #E31F25;
    --colorNeutral: #daccc0;
    --colorText: #2b2b2a;
    --colorTextAlt: #fff;
    --colorBackground: #fff;
    --colorBackgroundSecondary: rgba(218, 204, 192, 0.36);
    --navBarHeight: 70px;
    --colorBackgroundAlt: rgba(218, 204, 192, 0.36);
    --colorError: #c10000;
    --colorSuccess: #3eb456;
    --animationSpeed: 0.2s;
}

* {
    box-sizing: border-box;
    position: relative;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    display:inline-block;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
   
::-webkit-scrollbar-thumb {
    background: rgb(143, 143, 143); 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(177, 177, 177); 
}

* [data-disabled]{
    pointer-events: none;
    opacity: 0.5;
}