@import '/src/styles/Responsive.scss';

.container{
    max-width: 1200px;
    width: 100%;
    margin: 4rem auto;

    & p{
        line-height: 1.75;
    }

    @include size_L{
        max-width: 950px;
    }

    @include size_M{
        max-width: 750px;
    }

    @include size_S{
        max-width: 580px;
    }

    &.container_padded{
        padding: 0 7rem;
    
        @include size_S {
            padding: 0 3rem;
        }
    
        @include size_XS {
            padding: 0 1.5rem;
        }
    }
}