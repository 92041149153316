@import '/src/styles/Responsive.scss';

.accent_box{
    padding: 2rem 7rem;
    background: var(--colorBackgroundSecondary);
    border-radius: 5px;

    @include size_S {
        padding: 2rem 3rem;
    }

    @include size_XS {
        padding: 2rem 1.5rem;
    }

    h2 {
        text-decoration: underline;
        font-size: 26px;
    }

    h3 {
        font-size: 22px;
    }
}
.credit_card_box{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;

    img {
        height: 30px;
        float: left;
    }
}
.legal_link {
    color: var(--colorPrimary);
}