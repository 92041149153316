@import '/src/styles/Responsive.scss';

.grid_gallery{
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min-content;
    gap: 1rem;

    @include size_S{
        gap: 0.5rem;
    }

    & .gallery_item{
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
        &::before{
            content: '';
            display: inline-block;
            padding-top: 100%;
        }
    }
}