@import '/src/styles/Responsive.scss';

.card {
    width: 100%;
    height: 100%;
    border-radius: 0.7rem;
    background-color: rgb(0 0 0 / 7%);
    overflow: hidden;
    display: flex;

    &.card_horizontal{
        flex-direction: row;

        & .card_media{
            width: 15%;
        }

        & .card_content_box {
            width: 50%;
        }

        @include size_S{
            flex-direction: column;

            & .card_media{
                width: 100%;
                padding-top: 56.25%;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                margin: 0
            }
    
            & .card_content_box {
                width: 100%;
            }
        }
    }

    &:not(.card_horizontal){
        flex-direction: column;
        
        .card_media{
            padding: 2rem;
        }

        .card_actions {
            margin-top: auto;
            width: 100%;
        }
    }

    & .card_media{
        width: 100%;
        height: 40%;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            border-radius: inherit;
        }

        & .card_media_overlay{
            width: 100%;
            height: 100%;
            border-radius: inherit;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    & .card_content_box {
        padding: 1.5rem;
        padding-bottom: 0.8rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .card_title {
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;
    }

    & .card_content {
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .card_subtitle {
        opacity: 0.7;
    }

    & .card_info_box{
        padding: 1.5rem;
    }

    & .card_actions_box {
        display: flex;
        align-items: center;
        padding: 1.5rem;
    }
}