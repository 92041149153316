@mixin size_L {
    @media (max-width: 1200px) { @content; }
}
@mixin size_M {
    @media (max-width: 992px) { @content; }
}
@mixin size_S {
    @media (max-width: 768px) { @content; }
}
@mixin size_XS {
    @media (max-width: 600px) { @content; }
}