@import '/src/styles/Responsive.scss';

.hero_section{
    height: calc(100vh - var(--navBarHeight));
    background: url('../../../public/images/hero-cover.jpg') rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;

    @include size_M {
        background: url('../../../public/images/hero-cover-sm.jpg') rgba(0, 0, 0, 0.3);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: multiply;
    }

    & .hero_title{
        width: 400px;
        margin-left: 5%;

        & img {
            height: 100%;
            width: 100%;
        }
    }

    & .hero_subtitle{
        color: var(--colorTextAlt);
        font-size: 16px;
        line-height: 1.56;
    }

    @include size_M{
        justify-content: center;

        & .hero_title{
            margin: 1rem;
            text-align: center;
        }
    }
}