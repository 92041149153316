@import '/src/styles/Responsive.scss';

.footer{
    color: var(--colorNeutral);
    background: url('../../../public/images/footer_background.jpg') rgba(0, 0, 0, 0.85);
    background-size: cover;
    background-blend-mode: multiply;
    background-position: 0 75%;
    margin-top: 4rem;
    display: flex;

    & .container{
        margin-top: 0;
        margin-bottom: 0;
    }

    & .footer_content{
        display: flex;
        margin-top: 3rem;
        border-top: 1px solid;
        border-bottom: 1px solid;

        @include size_S{
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        & > *{
            flex-basis: 25%;
            padding: 2rem 0;
        }

        & .contact_wrapper{
            font-size: 13px;
            line-height: 2.15;
        }

        & .section_wrapper{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            margin-left: 3rem;

            @include size_S{
                margin-left: 0;
            }

            & > *{
                padding: 0.6rem 0;
            }

            & a{
                text-transform: uppercase;
            }

            & .social_media_wrapper{ 
                display: flex;
                gap: 10px;

                & a{
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: var(--colorNeutral);
                    color: var(--colorText);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        & .payment_type_wrapper {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            gap: 15px;

            @include size_S{
                justify-content: center;
            }
            
            & img {
                height: 30px;
            }
        }
    }

    & .footer_bottom_content{
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;

        & > *{
            padding: 1rem 0;
        }

        @include size_S{
            flex-direction: column-reverse;
            text-align: center;
        }

        & .footer_bottom_content_details_section{
            display: flex;
            gap: 20px;

            @include size_S{
                flex-direction: column;
            }
        }
    }
}